body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: Inter;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Inter';
  src: url('../src/assets/fonts/Inter-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../src/assets/fonts/Inter-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../src/assets/fonts/Inter-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-feature-settings: 'liga' off, 'clig' off;
}

@font-face {
  font-family: 'Inter';
  src: url('../src/assets/fonts/Inter-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Maven Pro';
  src: url('../src/assets/fonts/MavenPro-Bold.ttf');
  font-feature-settings: 'liga' off, 'clig' off;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}